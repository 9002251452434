import { trackPage } from './utils/tracking'
import JqueryTracking from './utils/tracking/jqueryTracking'
import { BrowserLogger } from 'client/shared/js/utils/browser'

document.addEventListener('DOMContentLoaded', () => {
  JqueryTracking($)
  BrowserLogger()

  trackPage({
    'PageName': 'Home Page'
  })
})

if (__DEV__) {
  if (module.hot) {
    module.hot.accept()
  }
}
